 .contact-box {
    background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
    padding: 80px 30px 80px 30px;
    border-radius: 5px 5px 5px 5px; 
    margin-top: 60px;
     margin-bottom: 60px;
}
.mb-45 {
    margin-bottom: 45px;
}
.sec-title {
    position: relative;
}
.sec-title .title {
    font-size: 30px;
    line-height: 46px;
    color: #fff;
    margin: 0;
}
 .address-box {
    display: flex;
}
 .address-icon {
    background-image: linear-gradient(275deg, #ffffff 0%, #fcfcff 100%);
    min-width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    width: 40px;
    border-radius: 50%;
    margin-right: 23px;
}
 .address-icon svg {
    font-size: 20px;
    line-height: 20px;
    color: #03228F;
}
.address-text .label {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
}
.address-text a {
    color: #ffffff;
}
 .address-text .desc {
    color: #ffffff;
}
.mb-25 {
    margin-bottom: 25px;
}
.mb-30 {
    margin-bottom: 30px;
}
.sec-title2 {
    position: relative;
}
.mb-40 {
    margin-bottom: 40px;
}
.contact {
    color: #742390;
    margin-top: 60px;
    float: left;
    width: 100%; 
    font-size: 18px;
}
.pl-70 {
    padding-left: 70px !important;
}
.sec-title2 .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    color: #0a0a0a;
    max-width: 100%;
    margin: 0 auto;
}
.contact-widget .from-control {
    padding: 20px 20px 20px 20px;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    background-color: #F6F7F9;
    width: 100%;
    max-width: 100%;
}
 .contact-widget .from-control {
    padding: 20px 20px 20px 20px;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    background-color: #F6F7F9;
    width: 100%;
    max-width: 100%;
}
.readon.learn-more.submit {
    background: linear-gradient(268deg, #ae3bc5 0%, #5d3aa6 100%);
    padding: 16px 40px 16px 40px;
border: 0px; color: #fff; width: 240px;
    font-size: 16px; border-radius: 40px;
}
.readon.learn-more.submit:hover{
    background: linear-gradient(268deg, #5d3aa6 0%, #ae3bc5 100%);
}