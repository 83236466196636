@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

body {
  font-family: 'Varela', sans-serif;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

.vlog-area iframe {
  width: 100%;
  height: 160px;
}

.vlog-area h3 {
  font-size: 16px;
  margin-top: 12px;
  border-top: dashed 1px #efe6e6;
  padding-top: 10px;
}

.google-map iframe {
  width: 100%;
  height: 250px;
}

.cijyDC {

  padding: 6px !important;
  margin-bottom: 4px;
}

.cijyDC img:hover {
  filter: grayscale(0%);
}

.cijyDC img {
  border-radius: 6px;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}

.row {
  width: 100%;
}

.dis-hide-show {
  display: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;

}

h1 {
  font-size: 55px !important;
}

h2 {
  font-size: 35px !important;
}

h4 {
  font-size: 24px;
  font-weight: 100 !important;
  color: #742390;
}

h5 {
  font-size: 24px;
}

p {
  font-size: 15px; line-height: 24px;
}







/* work-process */
.work-process {
  width: 100%;
  float: left;
  margin-top: 50px;
}

.work-process h2 {
  margin-bottom: 60px;
}

.feature-s4 {
  align-items: center;
  background: url(../src/assets/images/workp.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 340px;
  position: relative;
}

.feature-area-inner>* {
  width: 33% !important;
}

.feature-s4 .badge {
  background: #4d4bb6;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  height: 68px;
  line-height: 44px;
  position: absolute;
  right: 92px;
  text-align: center;
  top: 20px;
  transition: all .2s ease-in-out 0s;
  width: 68px;
}

.feature-s4 .content {
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 124, 251, .16);
  display: flex;
  flex-direction: column;
  height: 280px;
  justify-content: center;
  margin-left: 20px;
  padding: 30px;
  text-align: center;
  transition: all .3s ease-in-out 0s;
  width: 280px;
}

.feature-s4 .content h3 {
  color: #1c1c1c;
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 6px;
  transition: all .3s ease-in-out 0s;
}

.feature-s4 .content p {
  color: #535353;
  line-height: 24px;
  margin-bottom: 0;
}

/* services */
.services {
  background-color: #F6FAFF;
  background-image: url(../src/assets/images/1bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 50px;
  float: left;
  width: 100%;
  padding-bottom: 60px;
  overflow: hidden;
}

.left-block {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 9px 30px 0px #EDF0F2;
  padding: 35px;
}

.left-block img {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 25px;
}

.left-block p {
  font-size: 15px;
}

.service-box-five2 {
  padding: 28px 22px 42px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 9px 30px 0px #EDF0F2;
  text-align: center;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  border-top: 4px solid transparent;
  margin-bottom: 22px;
  min-height: 344px;
}

.service-icon-five {
  height: 80px;
  width: 80px;
  background: #EDF4FE;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  border-radius: 100px;
  color: var(--thm-color);
  margin-bottom: 21px;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
}

.service-box-five2 h4 {
  font-weight: 500 !important;
  font-size: 19px;
  color: #000;
}

.service-box-five2 p {
  font-size: 15px;
}

.service-icon-five img {
  width: 33px;
}

.work-process h4 {
  font-size: 20px;
}

.p-text-justi {
  text-align: justify;
}

.rs-animation-image {
  position: relative;
  top: 80px;
}

.pattern-img {
  text-align: center;
  z-index: -1;
}

.pattern-img img {
  max-width: 100%;
  height: auto;
  left: 54px;
  position: relative;
}

.middle-img {
  position: absolute;
  left: 10px;
  top: -30px;
}

.dance3 {
  -webkit-animation: dance3 8s alternate infinite;
}

/*Pulse Border Animation*/

@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}

.dance3 {
  -webkit-animation: dance3 8s alternate infinite;
}

@keyframes dance3 {
  0% {
    -webkit-transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.dance {
  -webkit-animation: dance 2s alternate infinite;
}

@keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

section.company-status {
  padding: 30px;
  background: #fafafa;
}

.status-box {
  border-right: dashed 1px #ebeaea;

}

.status-box .counter {
  font-size: 33px;
  font-weight: bold;
  width: 100%;
  position: relative;
  float: left;
}

.status-box .counter:after {
  content: '+';
}

.mt-150 {
  margin-top: 150px;
}

.product {
  margin-bottom: 26px;
}

.product img {
  border-radius: 0px;
  border: solid 1px #f4f4f4;
}

.product h3 {
  font-size: 19px;
}

.product p {
  color: #7c7a7a;
}

.productall {
  background-image: url(../src/assets/images/bgs.webp);
}

.vblog {
  width: 100%;
  min-width: 100%;
  padding-top: 0px;
  padding-bottom: 50px;
  position: relative;
  margin-top: 110px;
  overflow: hidden;
  background: url(../src/assets/images/hero-bg-4.jpg) no-repeat center center / cover fixed;
}

.vblog:before {
  background: linear-gradient(to bottom, rgba(3, 25, 116, 0.7), rgba(25, 52, 167, 0.95));
  content: "";
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.vblog-inner {
  position: relative;
  z-index: 9;
  margin: auto;
  display: table;
}

.vblog-inner h3 {
  color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.vblog-inner iframe {
  border: dashed 1px #5e74b7;
  padding: 6px;
  width: 450px;
  height: 250px;
  background: #101b48;
  margin: auto;
  display: table;
  /* margin-top: 40px !important; */
}

.company-slide {
  max-width: 210px;
  min-height: 120px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px !important;
  padding: 14px;
}

.slider-icon {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 100px;
  border: solid 7px #eaeaec;
  margin: auto;
  line-height: 61px;
  text-align: center;
  margin-top: 10px;
}

.slider-icon img {
  width: 54px;
}

.company-slide h5 {
  font-weight: 400;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 2px;
  position: relative;
  padding-left: 7px;
}

.company-slide h5:before {
  width: 50px;
  height: 2px;
  background: #1086cf;
  content: "";
  position: absolute;
  top: -9px;
}

.slider-custom .carousel-indicators {
  display: none;
}

.slider-custom .carousel-control-prev {
  opacity: 1 !important;
  position: absolute;
  right: 93px !important;
  width: 60px !important;
  height: 36px !important;
  left: inherit !important;
  background: linear-gradient(180deg, #1685c8 0%, #0881cb 100%) !important;
  border-radius: 40px 0px 0px 40px;
  padding: 19px !important;
  top: -60px !important;
}

.slider-custom .carousel-control-prev:hover {
  background: linear-gradient(180deg, #115d8d 0%, #2393d7 100%) !important;
}

.slider-custom .carousel-control-next {
  opacity: 1 !important;
  position: absolute;
  right: 43px !important;
  width: 60px !important;
  height: 36px !important;
  background: linear-gradient(180deg, #2896d9 0%, #2393d7 100%) !important;
  border-radius: 0px 40px 40px 0px;
  padding: 19px !important;
  top: -60px !important;
  display: none;
}

.slider-custom .carousel-control-next:hover {
  background: linear-gradient(180deg, #094b74 0%, #1685c8 100%) !important;
}

.slider-custom .owl-nav button.owl-prev {
  opacity: 1 !important;
  position: absolute;
  right: 93px !important;
  width: 60px !important;
  display: none;
  height: 36px !important;
  left: inherit !important;
  background: linear-gradient(180deg, #1685c8 0%, #0881cb 100%) !important;
  border-radius: 40px 0px 0px 40px;
  padding: 19px !important;
  top: -60px !important;
  color: #fff !important;
  font-size: 26px !important;
}

.slider-custom .owl-nav button.owl-prev span {
  top: -4px;
  position: relative;
}

.slider-custom .owl-nav button.owl-prev:hover {
  background: linear-gradient(180deg, #115d8d 0%, #2393d7 100%) !important;
}

.slider-custom .owl-nav button.owl-next {
  opacity: 1 !important;
  position: absolute;
  right: 43px !important;
  display: none;
  width: 60px !important;
  height: 36px !important;
  background: linear-gradient(180deg, #2896d9 0%, #2393d7 100%) !important;
  border-radius: 0px 40px 40px 0px;
  padding: 19px !important;
  top: -60px !important;
  color: #fff !important;
  font-size: 26px !important;
}

.slider-custom .owl-nav button.owl-next span {
  top: -4px;
  position: relative;
}

.slider-custom .owl-nav button.owl-next:hover {
  background: linear-gradient(180deg, #094b74 0%, #1685c8 100%) !important;
}

section.client-bg {
  background: #f5f7fa;
  /* height: 100vh; */
  padding: 60px 0px;
}

.partners {
  background: linear-gradient(330deg, #00005b 0%, #020275 90%) !important;
}


/* sup page css */
.sub-page-banner {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  background: url(./assets/images/hero-5.jpg) no-repeat center center / cover;
  padding: 70px 0;

}

.sub-page-banner-hub{
  background: url(./assets/images/banner2.jpg) no-repeat center center / cover;
  padding: 200px 0;
}
.lqd-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;bottom: -180px;
  direction: ltr; 
}
.lqd-shape svg {
  display: block;
  width: calc(100% + 2px);
  position: relative;
}
.about-img {
  margin-top: 180px;
  z-index: 1;
  left: 122px;
  position: relative;
}
@-webkit-keyframes round-01 {
0% {
  transform: rotate(15deg);
}
50% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(15deg);
}
}

@keyframes round-01 {
0% {
  transform: rotate(15deg);
}
50% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(15deg);
}
}
.image {
  padding-left: 76px;
  padding-top: 3px;
}
.image img {
  border-radius: 50%;
}
.manager-content h3 {
  font-size: 29px;
  color: #004f9f;
  margin-bottom: 4px;
}
.shape-1 {
position: absolute;
left: 0;
top: -25%;
-webkit-animation: round-01 7s linear infinite;
        animation: round-01 7s linear infinite;
z-index: -1;
}
.contact-bg {
  background: url(./assets/images/contact.jpg) no-repeat center center / cover;
}
 .banner-shap {
  right: 5%;
  position: absolute;
  animation: skill-shap-animi 10s infinite linear;
  z-index: 99;
 
}
.part-client-bg {
  background: url(./assets/images/part.png) no-repeat center center / cover;
}
@keyframes skill-shap-animi {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.newsr-bg {
  background: url(./assets/images/newsr.png) no-repeat center center / cover;
}





.sub-page-banner:before {
  content: "";

  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(90deg, #121522, rgba(25, 52, 167, 0.5));
}

.sup-page-inner {
  position: relative;
  z-index: 9;
}

.sup-page-inner h2 {
  color: #fff;
  font-size: 40px !important;
}

.custom-breadcrumb {
  text-align: center !important;
  display: inline-block;
}

.custom-breadcrumb a {
  color: #fff;
}


.rs-animation-shape {
  position: relative;
  display: inline-block;
  margin-top: 150px;
}

.eatlbd-shape {
  position: absolute;
  content: "";
  left: -20%;
  bottom: -15%;
  width: 550px;
  height: 550px;
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  animation: sliderShape 5s linear infinite;
  display: block;
  z-index: -1;
  -webkit-animation: sliderShape 5s linear infinite;
}

.eatlbd-shape img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.eatlbg-im {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

@keyframes sliderShape {

  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }

  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

.about-company {
  margin-top: 150px;
  left: 50px;
  position: relative;
}

.about-company h5 {
  position: relative;
  padding-left: 20px;
  font-size: 17px;
  margin-bottom: 10px;
}

.about-company h5:before {
  content: "";
  height: 8px;
  width: 8px;
  background: #713bae;
  top: 0;
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  border-radius: 2px;
}

.list-style-one {
  padding: 0px;
}

.list-style-one li {
  position: relative;
  list-style: none;
  padding-left: 28px;
  margin-bottom: 10px;
}

.list-style-one li:before {
  width: 25px;
  height: 25px;
  color: white;
  position: absolute;
  top: -1px;
  left: -5px;
  content: url(./assets/images/correct.svg);
}

.about-company h3 {
  font-size: 21px;
  margin-bottom: 12px;
}

.left-0 {
  left: 0px !important;
}

.border-dashed {
  border-top: 1px dashed #e8e9eb;
}

.mt-70 {
  margin-top: 70px !important;
}

img.services-box {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;


}

.line-h {
  line-height: 30px;
}

.about-company p {
  text-align: justify; font-size: 15px;
}

.par-client .item {
  padding: 1px 0px 0px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 9px 30px 0px #EDF0F2;
  float: left;
  /* width: 250px; */
  margin-left: 18px;
  margin-bottom: 20px;
  width: 235px;
}

.par-client .item img {
  width: 100%;height: 90px;
  object-fit: contain;
}

.newsrom-block {
  box-shadow: 0 0 30px 0 rgba(77, 77, 119, .1);
  width: 100%;
  float: left;
  padding: 12px;
  margin-bottom: 22px;
  border-radius: 7px;
}

.news-thumb {
  width: 150px;
  height: 100px;
  float: left;
  overflow: hidden;
}

.news-thumb img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: width 1s, height 1s;
}

.newsrom-block:hover img {
  transition: width 1s, height 1s;
  width: 160px;
  height: 120px;
}

.news-right {
  width:70%;
  float: left;
  margin-left: 14px;
  text-align: left;
}

.news-right h5 {
  font-size: 17px !important;
  color: #742391;
  margin-bottom: 4px;
}

.newsrom-block:hover h5 {
  color: #184a96;
}

.news-right p {
  color: #999;
  font-size: 15px;
  margin-bottom: 0px;
}

span.date-title {
  color: #999;
}

img.news-details {
  width: 350px;
  margin: auto;
  display: table;
  border-radius: 12px;
  height: 350px;
  object-fit: cover;
  border: dashed 1px #e7e5e8;
  padding: 7px;
  border-radius: 100%;
  /* margin: auto; */
  /* display: table; */
  margin-top: 52px;
  margin-bottom: 60px;
}

.ds-part {
  height: 200px;
  overflow: hidden;
}

.border-topn{
  border-top: dashed 1px #ededed;
}
.nu-all{
  width: 280px; margin: auto; display: table;
}
.left0-all{
  left: 18px;
}
h5.fonsize-news{
  font-size: 23px;
}
.border-left-news {
  border-left: solid 3px #772793;
}
span.date-sp {
  color: #999;
}
.border-left-news h5{
  font-size: 16px !important;
}
.yarl__slide_captions_container.yarl__slide_title_container {
  /* background: #491b51; */
  background-image: linear-gradient(90deg, #7e1987 0%, #0199fe 100%);
  text-align: center;
}
.media-galery img.sc-kAyceB {
  border: dashed 1px rgb(136, 136, 136);
  padding: 2px;
  /* box-shadow: 0px 0px 2px #000; */
}
.media-galery .cijyDC img{
  filter: grayscale(0%);
}
.inv-box img {
  height: 190px;
  object-fit: cover;
}
.inv-box .left-block {
  min-height: 470px !important;
}
.inv-box2 .left-block {
  min-height: 390px !important;
  /* border: solid; */
}
.project-box .left-block img {
  height: 150px;
  object-fit: cover;
}
.services-all-list .service-box-five2 {
  min-height: 364px;
}
img.work-p {
  margin: auto;
 width:64%; 
  display: table; border-radius:20px;
}
.left-0-b{
  left: 0px !important;
}
img.pro-big {
  width: 100%; height: 100%; border: dashed 1px #ecebeb; 
}
.border-big-p{
  border: dashed 1px #ddd;  padding: 12px;
}
.owl-carousel .owl-item img {

  height: 130px;
  object-fit: contain;
}
.vlog-area h3 {
  font-size: 15px;
  min-height: 80px;
  line-height: 22px;
  color: #6c2c98;
  /* font-family: arial; */
}
/* ====================================================responsive css ===========================================================*/

@media (min-width:200px) and (max-width:1480px) {
.about-img {
  left: 32px;

}
}



@media (min-width:200px) and (max-width:1399px) {
.feature-s4 .content {
  height: 240px;
  padding: 20px;
  width: 240px;
}
.news-right {
  width: 51%;
}
.shape-1{
  width: 400px;
}
.image img {
  width: 300px !important;
  left: 0px;

}
.image {
  position: relative;
  right: 28px;
  top: -8px;
}
}

@media (min-width:200px) and (max-width:1300px) {
.eatlbd-shape {
  left: -4%;
  bottom: 5%;width: 440px; height: 400px;
}
}

@media (min-width:200px) and (max-width:1199px) {
  .customHeader #basic-navbar-nav a {
    padding: 14px 9px;
    font-size: 15px;
  }
  .container{
    max-width: 1140px;
}
.rs-contact-info li {
  padding-right: 3px;
  padding-left: 3px;
  font-size: 13px;
}
.feature-s4 .content {
  height: 200px;
  padding: 10px;
  width: 200px;
}
.feature-s4 .content h3 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0px;
}
.feature-s4 .content p {
  line-height: 18px;
}
.md-pic {
  width: 100%;
}
.middle-img {

  left: -92px;
  top: -77px;
}
.eatlbd-shape {
  left: -9%;
  bottom: -8%;
  width: 110%;
  height: 110%;
}
}

@media (min-width:200px) and (max-width:991px) {
  .feature-s4 .content {
    border: solid 2px #c9e3fe; padding: 2px;
}
.feature-s4{
  background: transparent;
}

.feature-s4 .content {
  height: 190px;
  width: 100%;
  border-radius: 30px; margin: auto;
}
.service-box-five2 {
  margin-bottom: 0px;
  margin-top: 22px;
  left: 14px; min-height: auto;
  position: relative;
}
.mt-red-re{
  margin-top: 30px;
}
.md-pic {
  width: 300px !important;
}
.mb-details{
  text-align: center;
}
.work-process{
  margin-top: 30px;
}
.column-reverse2{
  -ms-flex-direction: column-reverse!important;
  flex-direction: column-reverse!important;
}
.pattern-img img{
  left: 0px;
}
.middle-img {
  left: -22px;
  top: -37px;
}
.rs-animation-image {
  margin-bottom: 100px;
}
.item {
  text-align: center;
  margin: auto;
  display: table;
}
img.services-box{
  margin-bottom: 22px;
}
.mb-5.pt-5.mt-5.mtpt-custom{
  padding-top: 0px !important; margin-top: 30px !important;
}
.about-img {
  left: inherit;
  margin: auto;
  display: table;
  margin-top: 100px; 
}
h4.mt-3.in-h4{
  margin-top: 100px !important;
}
img.work-p {

 width:100%; 
 
}
}




@media (min-width:200px) and (max-width:767px) {
  .work-process h2{
    margin-bottom: 30px;
  }
  section.company-status {
    padding: 10px;
  }
  .status-box .counter{
    font-size: 22px;
  }
  .status-box{
    border-right: 0px; margin-bottom:6px;
  }
  .middle-img {
    left: -102px;
    top: -59px;
}
.rs-animation-image {
  width: 320px;
  margin: auto; margin-bottom: 90px;
}
.middle-img {
  left: -23px;
  top: 1px;
  /* width: 100%; */
}
.middle-img img {
  width: 370px;
}
.work-process{
  text-align: center;
}
.rs-animation-shape {
  margin: auto;
  display: table;
  margin-top: 112px;
}
.eatlbd-shape {
  left: inherit;
  bottom: 0px;
  width: 320px;
  height: 320px;
}
.eatlbg-im {
  height: auto;
  max-width: 300px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.about-company {
  margin-top: 90px;
  left: 0px;
  position: relative;
}
.sup-page-inner h2{
  font-size: 19px !important;
}
img.services-box{
 left: 22px;
  position: relative;
}
.mt-70 {
  margin-top: 40px !important;
}
.sub-page-banner{
  padding: 40px 0; padding-bottom: 24px;
}
.ds-part{
  height: auto;
}
.par-client .item {
  float: inherit;
  margin: auto;
  margin-bottom: 14px;
}
h2 {
  font-size: 23px !important;
}
.contact-widget .from-control {
  padding: 10px 10px 10px 10px !important;

}
.mb-30 {
  margin-bottom: 10px !important; 
}
.pl-70 {
  padding-left: 60px !important;
}

.address-icon {
  min-width: 30px !important;
  height: 30px !important;
  line-height: 28px !important;
  width: 30px !important;
  border-radius: 100%;
  margin-right: 7px !important;
}
.address-icon svg {
  font-size: 14px !important;
  line-height: 10px !important;

}
.readon.learn-more.submit {

  padding: 12px 40px 12px 40px !important;
}
.contact-box{
  margin-left: 20px;
}
img.news-details{
  width: 300px;
  height: 300px;
}
.sub-page-banner-hub {
  
  padding: 140px 0 !important;
}
}

@media (min-width:200px) and (max-width:600px) {
.feature-s4 {
  width: 100% !important;
  max-width: 100%;
  display: grid; height: auto; margin-bottom: 40px;
}
.feature-s4 .content {
  height: 100%;
  margin: auto;
  min-height: 120px;padding: 0px 20px;
}
.feature-s4 .badge{
  top: -14px;
}
.feature-area-inner {
  padding-left: 20px;
}

.feature-s4 .badge {
  font-size: 20px;
  height: 55px;
  line-height: 26px;
  right: 12px;
  top: -25px;
  width: 55px;
}
.vblog-inner iframe{
  width: 100%;
}
.news-thumb {
  float: initial;
  margin: auto;
  display: table;
  border-radius: 10px;
  margin-bottom: 15px;
}
.news-right {
  width: 100%;
  float: left;
  margin-left: 0px;
  text-align: center;
}
}


@media (min-width:200px) and (max-width:520px) {
.shape-1 {
  width: 300px !important;
}
.image img {
  width: 210px !important;
}
.image {
  right: 28px;
  top: 4px;
}
}