.navbar-expand{
    padding: 0px;
}
img.logo-top {
    width: 220px;
}
.tolp-header{
    background-color: transparent;
    padding: 12px 30px 12px 30px;
}
.customHeader #basic-navbar-nav a {
    padding: 14px 16px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;

    text-decoration: none; position: relative;
    color: #fff;
}

.rs-contact-info {
    list-style: outside none none;
    margin: 0;
    padding: 0;margin-top: 10px;
}
.rs-contact-info li {
    display: inline-block;
    padding-right: 25px;
    padding-left: 25px;
    border-right: 1px dashed #eee;
    font-size: 14px;
}
.contact-info {
    float: left;
    text-align: left;
    display: block;
}
.contact-info span {
    color: #0a0a0a;
    display: block;
    text-align: left;
    font-size: 15px;
    padding: 0;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
}
 .contact-part img {
    float: left;
    margin-right: 15px;
    line-height: 38px;
}
.customHeader #basic-navbar-nav a:hover {
    color: #866cf3;
}
nav.customHeader {
    background-image: linear-gradient(90deg, #7e1987 0%, #0199fe 100%);
}
.useravatar-dropdown .dropdown-toggle {
    padding: 0px;
    background: transparent !important;
    border: 0px;
    margin: 0px;
    height: 44px;
}
.useravatar-dropdown .dropdown-toggle::after{
display: none;
}

.custom-navbar .dropdown-toggle::after{
    display: none;
}
.setting-drop-down .dropdown-menu{
    left: -92px;
    margin-top: 12px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 23%);
    font-size: 17px;
    border: 0px;
}
.setting-drop-down .dropdown-menu .dropdown-item{
    border-bottom: solid 1px #f1f1f1;
}
.setting-drop-down .dropdown-menu .dropdown-item a{
    color: #212529;
}
div.all-drop-down a.dropdown-item {
    color: #212529 !important;
}
div.all-drop-down a.dropdown-item:hover{
    color: #8b82fe !important;  
}

.custom-navbar .active{
    position: relative !important;
}
.custom-navbar .active:after{
    width: 52%;
    height: 1px;
    background: #fff;
    content: '';
    position: absolute;
    margin: auto;
    display: table;
    left: 0px;
    right: 0px;
    bottom: 10px;
}
.home-mar {
    top: -2px;
    position: relative;
}
.drop-down-nav{
    position: inherit !important;
}
.drop-down-nav .dropdown-toggle{
    position: relative;
}
.drop-down-nav .dropdown-toggle:after {
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 5px #d3dce4;
    border-bottom: solid 5px transparent;
    content: '';
    display: block;
    position: absolute;
    right: 2px;
    top: 24px;
}

.drop-down-nav .dropdown-menu{
    position: absolute;
    width: 240px;
    padding: 40px 0px;
    border-radius: 0px;
    padding: 12px;
    left: 113px !important;
    margin-top: 8px !important;
    background-image: linear-gradient(90deg, #66319e 0%, #5a3faa 100%);
    border: 0px;
}
.drop-down-nav .dropdown-menu h4{
    font-size: 22px;
    width: 100%;
    float: left; 
    margin-bottom: 8px; color: #fff;
}
.drop-down-nav .dropdown-item{
    padding-left: 0px !important;margin-bottom:5px;
}

.drop-down-nav .dropdown-item a{
    font-size: 14px !important; 
    padding-left: 0px !important; color: #e2e0e0 !important;
   
    
}
.drop-down-nav .dropdown-menu a:hover {
    background: transparent;
    color: #fff !important;
}
.drop-down-nav .active:after{
    background: #b8abdb;
    left: -54px;
    bottom: -2px;
}
button.loginbtn {
    background: #089699;
    color: #fff;
    border: 0px;
    border-radius: 16px 0px 16px 0px;
    padding: 1px 18px;
    top:8px;
    position: absolute;
    cursor: pointer;
    right: 20px;
}
.drop-down-nav .dropdown-menu a {
    padding: 0px !important; float: left; width: 100%;
}
.custom-navbar-list .dropdown-menu a {
  margin-top:3px !important;

}
.drop-down-nav .block-item {
    width: 100%;
    height: 70px;
    margin-top: 12px;
}
.drop-down-nav .block-item span {
    background: rgb(255 255 255 / 40%);
    margin-top: 9px;
}
.drop-down-nav .block-item span img {
    width: 40px;
}
.drop-down-nav .block-item p {
    margin-top: 23px;

}

.sticky-nav   {
    position: fixed !important;
    top: 0px;
    left: 0;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
    padding: 8px 0px !important;
    width: 100%;
    z-index: 999;
  }
  .sticky-nav a {
    padding:4px 17px !important;
    font-size: 15px !important;
  }
  .sticky-nav .active:after {
    bottom: 3px;
}
.wd-25{
    width: 25%;
}
.wd-50{
    width: 40%;
}
.block-top-info {
    float: left;
    margin-top: 19px;
    top: 8px;
    position: relative;
}
.block-top-info span {
    float: left;
    font-size: 30px;
    color: #2584b1;
}
.top-details {
    width: 80%;
    float: left;
    left: 10px; 
    position: relative; margin-top: 15px;
    color: #868686;
}
.top-details  h5{
margin-bottom: 0px; font-size:15px;
  
}
.top-details p {
    float: left;
    font-size: 13px;margin-top: -3px;
}
  @keyframes smoothScroll {
    0% {
      transform: translateY(-142px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }

  button.btn-eatlhu {
    color: #fff;
    border: dashed 1px #7cafe0;
    border-radius: 40px;
    position: absolute;
    right: -210px;
    top: 9px;
    width: 160px;
    background: #2574dc !important;
}
.btn-eatlhu:hover{
    background: #1858af !important;color: #fff !important;
}
a.invlink.active:after {
    background: transparent !important;
    
}
.sticky-nav button.btn.btn-eatlhu {
    top: -4px;
}
  @media (min-width:200px) and (max-width:1399px) {
  .rs-contact-info li {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
}
button.btn-eatlhu {
    right: -130px;
    width: 150px;
}
  }

  @media (min-width:200px) and (max-width:991px) {

    .navbar-toggler {
        position: absolute;
        right: 16px;
        top: -8px;
        box-shadow: none;
        background-image: linear-gradient(90deg, #0d6cac 0%, #0d6cac 100%);
        width: 46px;
        height: 40px;
        border: 0px !important; 
        border-radius: 6px !important;
       
    }.drop-down-nav .dropdown-item a {

        color: #4e056f !important;
    }

    .drop-down-nav .dropdown-menu {
   padding: 0px;
        width: 100%;
        background: #e7e7e7;
        height: auto;
        float: left; padding-bottom: 30px;
    }
    button.btn-eatlhu {
        right: 0px;
        width: 150px;
        position: relative;
        top: 3px;
        border-radius: 0px;
    }
      .navbar-toggler-icon {
        background: url(../../assets/images/menu.svg) no-repeat !important;
        background: no-repeat; width: 50px !important; height: 48px !important;
      }
      .drop-down-nav .dropdown-toggle:after {
        border-top: solid 5px #ab9ebb;
        right: 59px;
        top: 16px;
    }
.topbar-responsive{
    display: none;
}
nav.customHeader{
    z-index: 9999;
}
nav.customHeader {
    background-image:none;
    position: absolute;
    right: 18px;
    top: 21px;
}
.navbar-toggler:focus{
    box-shadow: none !important;
}
.navbar-collapse {
    background: #fff;box-shadow: 0px 3px 5px #ddd;
    margin-top: 60px;
    width: 400px;
}
.customHeader #basic-navbar-nav a{
    text-align: right; color: #0d6cac;    border-bottom: dashed 1px #e7e7e7;
}
.custom-navbar .active:after {
    width: 40px;
    left: inherit;
    right: 9px;background: #e5adf3 !important;
   
}
.customHeader #basic-navbar-nav a {
    padding: 8px 10px !important;

}
.active{
    color: #9848ac  !important;
}
 #basic-navbar-nav .basic-nav-dropdown a{
    border-bottom: 0px !important;
}
div.dropdown-menu a.dropdown-item {
    margin: 0px;
    height: 34px;
}
}

@media (min-width:200px) and (max-width:1199px) {
    .customHeader #basic-navbar-nav a {
        padding: 12px 10px;
        font-size: 14px;
}
.sticky-nav a{
    padding: 2px 11px !important;  
}
}

@media (min-width: 200px) and (max-width: 991px){
.navbar-collapse {
    width: 300px;
}
.sticky-nav{
    position: absolute !important; right: 0px !important;    transition: inherit;
    animation: inherit;
}
}


@media (min-width: 200px) and (max-width: 400px){
img.logo-top {
    width: 200px;
}

.navbar-collapse {
    width: 280px;
}
}

@media (min-width: 200px) and (max-width: 350px){
img.banner-img {
    width: 270px !important;
}
}