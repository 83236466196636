.banner-section {
	width: 100%;
	height: 580px;
	display: flex;
	align-items: flex-end;
	position: relative;
	 background: url(../../assets/images/bg1.jpg) no-repeat center center; 
	background-size: cover;
	padding-bottom: 70px; overflow: hidden;
}
section.banner-section:before {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	content: '';
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	padding-bottom: 70px;
	top: 0px;
} 
.btn-custom {
	background: linear-gradient(268deg, #ae3bc5 0%, #5d3aa6 100%);
	border: 0px !important;
	white-space: nowrap;
}
.btn-custom:hover {
	background: linear-gradient(180deg, #5d3aa6 0%, #ae3bc5 100%);
}
.slider-banner h1 span {
	color: #3ddfe2 !important;
}

/* baner css */
.slider-banner {
	width: 100%;
	float: left;
	top: -110px;
	position: relative;
}
.slide-arrow .carousel-control-prev {
	display: none !important;
}
.slide-arrow .carousel-control-next {
	display: none !important;
}

img.banner-img {
    width: 450px;     
}
.slider-banner.slide-arrow .carousel-indicators [data-bs-target] {
	height: 2px;
}
.btn-big {
	width: 230px;
	padding: 11px !important;
	font-size: 18px !important;
	position: relative; border-radius: 40px;
}
.btn-big svg {
	position: absolute;
	right: 28px;
	top: 16px;
}
.carousel-inner{
	top: 120px;
	position: relative;
}
.slider-banner h1 {
	font-size: 46px !important;
    line-height: 59px;
    margin-top: 130px;
}
.slider-banner h1 label {
    font-weight: normal;
    font-size: 26px;line-height: 37px;
}
.carousel-indicators {
   bottom: -170px !important;
}
 .line-inner {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
	width: 90%;
    margin: auto;
    /* width: calc(100%); */
    pointer-events: none;
    overflow: hidden;
    height: 100vh;
    z-index: 0;
  }
  .line-inner .line {
	position: absolute;
	width: 1px;
	height: 100%;
	top: 0;
	left: calc(50%);
	background: #fff;
	z-index: 1;
  }
 .line-inner .line:after {
	content: "";
	display: block;
	position: absolute;
	height: 80px;
	width:2px;
	top: -20%;
	left: -1px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 39%, #1273eb 130%, #1273eb 59%);
	background-color: rgba(0, 0, 0, 0);
	-webkit-animation: rain-line 13s 0s linear infinite;
	animation: rain-line 13s 0s linear infinite;
	animation-delay: 0s; opacity: 0.4;
  }
 .line-inner .line:nth-child(1) {
	left: 2px !important;
  }
  .line-inner .line:nth-child(1):after {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
  }
.line-inner .line:nth-child(3) {
	left: unset !important;
	right: 2px;
  }
 .line-inner .line:nth-child(3):after {
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s;
  }
 .line-inner.style2 .line {
	background: rgba(255, 255, 255, 0.1);
  }
  .line-inner.style2 .line:after {
	background-color: #fff;
  }
 .line-inner.orange-dark .line {
	background: rgba(255, 255, 255, 0.1);
  }
  .line-inner.orange-dark .line:after {
	background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
  }
  @keyframes rain-line {
	0% {
	  top: -20%;
	}
	100% {
	  top: 100%;
	}
  }
  @-webkit-keyframes rain-line {
	0% {
	  top: -20%;
	}
	100% {
	  top: 100%;
	}
  }


  @media (min-width:200px) and (max-width:1199px) {

  img.banner-img{
	width: 100% !important;
  }
  .banner-section{
	height: 420px !important;
  }
  .slider-banner h1 {
	font-size: 33px !important;
	line-height: 43px;
	margin-top:60px !important;
  }
  .slider-banner h1 label {

    font-size: 23px;
    line-height: 29px;
}
.slider-banner {
    top: -80px;
}
}


@media (min-width:200px) and (max-width:991px) {
.btn-big {
    width: 210px;
    padding: 11px !important;
    font-size: 16px !important;
}
.slider-banner {
    top: -140px;
}
}

@media (min-width:200px) and (max-width:767px) {
	img.banner-img {
		width: 300px !important;
		margin: auto;
		display: table;
	}
	.banner-section {
		height: auto !important;
	}
	section.banner-section:before {
		
		height: 100%;
	}

	.column-reverse{
		-ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
	}
	img.banner-img {

		margin-top: 62px;
	}
	.slider-banner h1 {
		font-size: 30px !important;
		line-height: inherit;
		margin-top: 20px !important; text-align: center;
	}
	.slider-banner h1 br{
		display: none !important;
	}
	.slider-banner h1 strong{
		margin-right: 6px;
	}
	.btn-big{
		margin: auto; display: table !important; margin-bottom: 30px;
	}
	.slider-banner{
		left: 12px;
	}
}

@media (min-width:200px) and (max-width:500px) {
	img.banner-img {
		width: 200px !important;

	}
	.slider-banner h1 {
		font-size: 24px !important;
	}
	.slider-banner h1 label {
		font-size: 17px;
		line-height: 23px;
	}
}